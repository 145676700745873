const getApiUrl = () => {
  const hostname = window.location.hostname;

  if (hostname === 'intelli-scout.com') {
    return 'https://intelli-scout.com/api';
  } else if (hostname === 'www.intelli-scout.com') {
    return 'https://www.intelli-scout.com/api';
  } else if (hostname === 'ai.artecont.at') {
    return 'https://ai.artecont.at/api';
  } else if (hostname === 'www.ai.artecont.at') {
    return 'https://www.ai.artecont.at/api';
  } else {
    // Fallback to environment variable or default URL
    return 'https://intelli-scout.com/api';
  }
};

const config = {
  apiUrl: getApiUrl(),
};

export default config;