import React, { useState, useEffect, useContext  } from 'react';
import 'chart.js/auto';

import '../App.css';
import './ContentTypeSelector.css';
import '../feed/NewsArticleStyles.css';
import { UserContext } from '../AuthProvider';
import refreshIcon from '../images/refresh-outline.svg';
import copyIcon from '../images/copy-outline.svg'
import Section from '../Section';

import config from '../config';
import LinkedInContainer from './LinkedInContainer';
import TwitterContainer from './TwitterContainer';
import ArticleContainer from './ArticleContainer';
import EmailContainer from './EmailContainer';
import InstagramContainer from './InstagramContainer';
import FacebookContainer from './FacebookContainer';
import BlogContainer from './BlogContainer';

const CockpitContainer = ({ onClose, article, initialTab = 0 }) => {
    const { user } = useContext(UserContext);
  
    const [postText, setPostText] = useState();
    const [linkedinText, setLinkedinText] = useState();
    const [instagramText, setInstagramText] = useState();
    const [facebookText, setFacebookText] = useState();

    const [isLoading, setIsLoading] = useState(false);
  
    const [selectedImage, setSelectedImage] = useState(null);
    const [loadingImage, setLoadingImage] = useState(false); // For image generation

    const [loadingTweet, setLoadingTweet] = useState(false); // For tweet sending
    const [tweetSent, setTweetSent] = useState(false); // State to track if tweet was sent
  
    const [loadingPost, setLoadingPost] = useState(false); // For tweet sending
    const [postSent, setPostSent] = useState(false); // State to track if tweet was sent


    const [loadingInsta, setLoadingInsta] = useState(false); // For tweet sending
    const [instaSent, setInstaSent] = useState(false); // State to track if tweet was sent
  
    const [loadingLinkedIn, setLoadingLinkedIn] = useState(false);


    const [loadingFacebook, setLoadingFacebook] = useState(false); // For tweet sending
    const [facebookSent, setFacebookSent] = useState(false); // State to track if tweet was sent
  
    const [isTwitterConnected, setIsTwitterConnected] = useState(false);
    const [hasServiceAccess, setHasServiceAccess] = useState(false);
  

    const [loadingModifiedText, setLoadingModifiedText] = useState(false)
    const [returnedText, setReturnedText] = useState("");

    const [loadingEmailText, setLoadingEmailText] = useState(false)
    const [emailText, setEmailText] = useState("");
    const [blogText, setBlogText] = useState("");

    const [copyFeedback, setCopyFeedback] = useState('');
  
    const [currentTab, setCurrentTab] = useState(initialTab);
  
    const [postDisabled, setPostDisabled] = useState(false);


    const tabs = ['Twitter', 'Instagram', 'Facebook', 'LnkedIn', 'Artikel', 'Blog', 'E-Mail'];
  
  
    useEffect(() => {
      // Function to check service access
      const checkServiceAccess = async () => {
        if (user) {
          try {
            const response = await fetch(`${config.apiUrl}/user/check-service-access`, {
              method: 'GET',
              credentials: 'include', // Ensure cookies are sent
            });
            const data = await response.json();
            setHasServiceAccess(data.has_access);
          } catch (error) {
            console.error('Failed to check service access:', error);
          }
        } else {
          setHasServiceAccess(false); // No user logged in
        }
      };
  
      checkServiceAccess();
    }, [user]);
  
  
    useEffect(() => {
      // Function to check if Twitter is connected
      const checkTwitterConnection = async () => {
        if (user) {
          try {
            const response = await fetch(`${config.apiUrl}/user/twitter/connected`, {
              method: 'GET',
              credentials: 'include', // Ensure cookies are sent
            });
            const data = await response.json();
            setIsTwitterConnected(data.connected);
          } catch (error) {
            console.error('Failed to check Twitter connection:', error);
          }
        }
      };
  
      checkTwitterConnection();
    }, [user]);


    const handleTabClick = (index) => {
        setCurrentTab(index);
        if (index !== 0 && index !== 3) {
          setPostDisabled(true)

        } else {
          setPostDisabled(false)
        }
    };

    const post = () => {
        switch (currentTab) {
            case 0:
                handleSendTweet();
                break;
            case 1:
                handleSendInsta();
                break;
            case 2:
                handleSendFacebook();
                break;
            case 3:
                handleSendLinkedIn();
                break;
            default:
                console.error('No social media tag active but post initiated');
          }
    }
  
  
    const handleSendTweet = async () => {
      setLoadingPost(true);
      try {
        const response = await fetch(`${config.apiUrl}/tweet`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: postText,
            url: article.url, // URL of the article
            media_data: selectedImage // URL of the generated image
          }),
        });
  
        const data = await response.json();
        if (response.ok) {
          setTweetSent(true); // Update state when tweet is sent successfully
        } else {
          console.error('Error posting tweet:', data.message);
        }
      } catch (error) {
        console.error('Error posting tweet:', error);
      }
      setLoadingPost(false);
    };
  
    const handleSendInsta = async () => {
      setLoadingInsta(true);
      window.open('https://www.instagram.com', '_blank');
      setLoadingInsta(false);
    };
  
    const handleSendFacebook = () => {
      setLoadingFacebook(true);
      
      let shareUrl = 'https://www.facebook.com/sharer/sharer.php?';
      shareUrl += `u=${encodeURIComponent(article.url)}`;
  
      window.open(shareUrl, '_blank');
      setLoadingFacebook(false);
    };
    
    const handleSendLinkedIn = () => {
      setLoadingLinkedIn(true);
      let shareUrl = 'https://www.linkedin.com/sharing/share-offsite/?';
      shareUrl += `url=${encodeURIComponent(article.url)}`;
      shareUrl += `&title=${encodeURIComponent(article.title || '')}`;
      shareUrl += `&summary=${encodeURIComponent(postText)}`;
      shareUrl += `&source=${encodeURIComponent(window.location.hostname)}`;
      
      if (selectedImage) {
        shareUrl += `&image=${encodeURIComponent(selectedImage)}`;
      }
  
      window.open(shareUrl, '_blank');
      setLoadingLinkedIn(false);
    };
    
    
    
  
    return (
      <div className="popup">
        <div className="popup-inner">
          <div className="popup-header">
            <div className="tabs-container">
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => handleTabClick(index)}
                  className={`tab-button ${currentTab === index ? 'tab-active' : 'tab-inactive'}`}
                >
                  {tab}
                </button>
              ))}
            </div>
            <button className="close-button" onClick={onClose}>&times;</button>
          </div>
  
          <div className="popup-content">
            {currentTab === 0 && (
              <div style={{ height: '100%' }}>
                <TwitterContainer 
                  article={article}
                  postDisabled={postDisabled}
                  post={post}
                  loadingPost={loadingPost}
                  tweetSent={tweetSent}
                  copyFeedback={copyFeedback}
                  setCopyFeedback={setCopyFeedback}
                  hasServiceAccess={hasServiceAccess}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  postText={postText}
                  setPostText={setPostText}
                  loadingImage={loadingImage}
                  setLoadingImage={setLoadingImage}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            )}

            {currentTab === 1 && (
              <div style={{ height: '100%' }}>
                <InstagramContainer 
                  article={article}
                  postDisabled={false}
                  post={post}
                  copyFeedback={copyFeedback}
                  setCopyFeedback={setCopyFeedback}
                  hasServiceAccess={hasServiceAccess}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  postText={instagramText}
                  setPostText={setInstagramText}
                  loadingImage={loadingImage}
                  setLoadingImage={setLoadingImage}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            )}

            {currentTab === 2 && (
              <div style={{ height: '100%' }}>
                <FacebookContainer 
                  article={article}
                  postDisabled={false}
                  post={post}
                  copyFeedback={copyFeedback}
                  setCopyFeedback={setCopyFeedback}
                  hasServiceAccess={hasServiceAccess}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  postText={facebookText}
                  setPostText={setFacebookText}
                  loadingImage={loadingImage}
                  setLoadingImage={setLoadingImage}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            )}

          {(currentTab === 3) && (
              <div style={{ height: '100%' }}>
                <LinkedInContainer 
                  article={article}
                  postDisabled={false}
                  post={post}
                  copyFeedback={copyFeedback}
                  setCopyFeedback={setCopyFeedback}
                  hasServiceAccess={hasServiceAccess}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  postText={linkedinText}
                  setPostText={setLinkedinText}
                  loadingImage={loadingImage}
                  setLoadingImage={setLoadingImage}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            )}

            {(currentTab === 4) && (
              <div>
                <ArticleContainer 
                  article={article}
                  hasServiceAccess={hasServiceAccess}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  returnedText={returnedText}
                  setReturnedText={setReturnedText}
                  setLoadingModifiedText={setLoadingModifiedText}
                />
              </div>
            )}

            {(currentTab === 5) && (
              <div>
                <BlogContainer 
                  article={article}
                  hasServiceAccess={hasServiceAccess}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  returnedText={blogText}
                  setReturnedText={setBlogText}
                  setLoadingModifiedText={setLoadingModifiedText}
                />
              </div>
            )}

            {currentTab === 6 && (
              <div>
                <EmailContainer 
                  article={article}
                  hasServiceAccess={hasServiceAccess}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  emailText={emailText}
                  setEmailText={setEmailText}
                  setLoadingEmailText={setLoadingEmailText}
                />
              </div>
            )}
          </div>

        </div>
      </div>
    );
};

export default CockpitContainer;
